import React from 'react';
import FileDownloader from './components/FileDownloader';
import DownloadTestFileButton from './components/DownloadTestFileButton';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <FileDownloader />
        <DownloadTestFileButton />
      </header>
    </div>
  );
}

export default App;