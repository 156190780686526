import React from 'react';

const DownloadTestFileButton = () => {
  const handleDownload = () => {
    // Construct the URL to your backend endpoint
    const url = 'http://localhost:8000/download-sample-file/';

    // Use the browser's built-in functionality to download the file
    window.location.href = url;
  };

  return (
    <button onClick={handleDownload}>
      Download test file
    </button>
  );
};

export default DownloadTestFileButton;
